import type { Product } from "~/models/Product"
import { ref } from "vue"
import { useStoreTypeStore } from "~/stores/StoreTypeStore"
import { useShopCartStore } from "~/stores/shopCartStore"
import { useNotification } from "~/composables/ShowNotification"
import { useRouter } from "vue-router"

const getImageId = (image: any) => {
    if (image && "id" in image) {
        return image.id
    } else {
        return ""
    }
}
export function useProductCardData(product: Product) {
    const shopCartStore = useShopCartStore()
    const notify = useNotification()
    const router = useRouter()
    const storeTypeStore = useStoreTypeStore()
    const { type } = storeToRefs(storeTypeStore)
    const slide = ref(
        product?.images?.length > 0 ? getImageId(product.images[0]) : -1
    )
    const ranting = ref(3)
    const quantity = ref(getDefaultQuantity())
    // const min_stock = ref(getMinStock())

    const add = (product: Product, quantity: number) => {
        const isCombo = ref(false);
        if(product.products){
            isCombo.value = true;
        }
        const form = {
            model: "product",
            quantity: quantity,
            productible: product.id,
            type: type.value,
            terms: undefined,
            isCombo: isCombo.value
        }

        return new Promise((resolve, reject) => {
            shopCartStore
                .addToCart(form)
                .then((res: any) => {
                    notify("positive", res.message)
                    resolve(res)
                })
                .catch((error: any) => {
                    notify("negative", error._data.message)
                    reject(error)
                })
        })
    }

    const show = (product: Product) => {
        router.push(`/products/${product.slug}`)
    }

    function getDefaultQuantity() {
        return product.min_quantity || 1
    }

    return {
        slide,
        ranting,
        quantity,
        add,
        show,
    }
}
