import { defineStore } from "pinia"

const WHOLESALE_STORE_TYPE = "mayorista"
const RETAILING_STORE_TYPE = "minorista"

const sync = async () => {
    const { data } = await useOFetch("GET", "store-type")

    return data
}

export const useStoreTypeStore = defineStore("store-type", {
    state: () => ({
        type: RETAILING_STORE_TYPE,
    }),
    getters: {
        is_store_wholesaler: (state) => {
            return state.type == WHOLESALE_STORE_TYPE
        },
    },
    actions: {
        setType(new_type: string) {
            this.type = new_type
        },
        async syncType() {
            const type = await sync()
            this.setType(type)
        },
    },
})
